import React, { useCallback, useEffect, useState } from "react";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import _noop from "lodash/noop";

import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import Modal from "@/components/Modal";
import PrintContent from "@/components/Print/PrintContent";
import PrintPortal from "@/components/Print/PrintPortal";

import { DeliveryForWaybill } from "@/Models/DeliveryForWaybill";
import { DELIVERY_STATUS_9 } from "@/Models/Delivery";

import {
  useAuthStore,
  useAgPostalCodesStore,
  useDeliveriesStore,
  useModalStore,
} from "@/stores/hooks";

import {
  SIZE_TYPE_150_V3,
  SIZE_TYPE_150_V2,
  SIZE_TYPE_200,
} from "@/utils/Print";

const Title = styled.h4`
  margin: 0;
`;

const FormLabel = styled(Form.Label)`
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 500;
`;

const FormLabelBold = styled.span`
  font-size: 16px;
  margin-left: 10px;
`;

const ButtonWrap = styled.div`
  margin-top: 40px;
  text-align: right;
`;
const ModalButton = styled(Button)`
  margin-left: 5px;
`;

const NarrowRow = styled(Row)`
  margin-right: -5px;
  margin-left: -5px;
`;
const NarrowCol = styled(Col)`
  padding-right: 5px;
  padding-left: 5px;
  word-break: break-all;

  ${({ align }) => align === "right" && `text-align: right`}
`;

const Count = styled(NarrowCol)`
  text-align: right;
`;

const DeliveriesWrap = styled.div`
  max-height: 250px;
  overflow: auto;
`;

const TextHighlight = styled.span`
  color: red;
`;

const SORT = {
  ALLOCATION_GROUP_NAME: "allocationGroupName",
  CENTER_PRODUCT_NAME: "centerAndproductName",
  PRODUCT_NAME: "productName",
  RECEIPT_DATE: "receiptDate",
};

const DeliveriesComponent = () => {
  const { deliveriesForWaybill } = useDeliveriesStore().state;

  return (
    <>
      {deliveriesForWaybill.map((delivery, idx) => {
        return (
          <NarrowRow key={idx}>
            <NarrowCol xs={3}>{delivery.productName}</NarrowCol>
            <NarrowCol xs={2} align="right">
              {(delivery.addressNotSupported ||
                delivery.status === DELIVERY_STATUS_9) && (
                <TextHighlight>(불가지역)</TextHighlight>
              )}
            </NarrowCol>
            <NarrowCol xs={5}>
              {delivery.bookId} | {delivery.receiverName}
            </NarrowCol>
            <Count>
              {idx + 1}/{deliveriesForWaybill.length}
            </Count>
          </NarrowRow>
        );
      })}
    </>
  );
};

export default function WaybillModal({ deliveries, onAfterSubmit = _noop }) {
  const { user } = useAuthStore().state;

  const { agPostalCodes } = useAgPostalCodesStore().state;
  const { ...agPostalCodesActions } = useAgPostalCodesStore();

  const { closeModal } = useModalStore();

  const { deliveriesForWaybill } = useDeliveriesStore().state;
  const { ...actions } = useDeliveriesStore();

  const { getValues, handleSubmit, register, reset } = useForm();

  const [showPrintWindow, setShowPrintWindow] = useState(false);

  useEffect(() => {
    reset({
      sizeType: SIZE_TYPE_150_V3,
      sort: SORT.RECEIPT_DATE,
    });
  }, []);

  useEffect(() => {
    if (deliveries?.length > 0) {
      sortDeliveries(SORT.RECEIPT_DATE);
    }
  }, [deliveries]);

  useEffect(() => {
    fetchAgPostalCodes();
  }, []);

  const fetchAgPostalCodes = useCallback(async () => {
    if (!agPostalCodes.length) {
      try {
        await agPostalCodesActions.fetchAll();
      } catch (e) {
        console.log(e);
      }
    }
  }, [agPostalCodesActions.fetchAll, agPostalCodes]);

  const onSubmit = useCallback(
    async (_data) => {
      try {
        await actions.printedBulk(deliveries);

        setShowPrintWindow(true);
        onAfterSubmit();
      } catch (e) {
        window.alert(`운송장 출력에 실패하였습니다.\n${e.message}`);
      }
    },
    [deliveries, onAfterSubmit],
  );

  // const filterDeliveries = useCallback((deliveries) => {
  //   if (getValues("disabledPrintForNotSupportedAddress")) {
  //     return deliveries.filter((d) => d.status !== DELIVERY_STATUS_9);
  //   } else {
  //     return deliveries;
  //   }
  // }, []);

  const getSortTarget = (delivery, sort) => {
    switch (sort) {
      case SORT.CENTER_PRODUCT_NAME:
        let str = "";
        if (delivery.receiverAllocationGroupName) {
          str += delivery.receiverAllocationGroupName.slice(0, 1);
        }
        str += delivery.productName;
        return str;
      case SORT.ALLOCATION_GROUP_NAME:
        return delivery["receiverAllocationGroupName"]?.trim();
      case SORT.PRODUCT_NAME:
      case SORT.RECEIPT_DATE:
      default:
        return delivery[sort]?.trim();
    }
  };

  const sortDeliveries = (sort) => {
    const _deliveries = deliveries.sort((a, b) => {
      const aValue = getSortTarget(a, sort);
      const bValue = getSortTarget(b, sort);

      if (aValue > bValue) {
        return 1;
      }
      if (aValue < bValue) {
        return -1;
      }
      return 0;
    });

    actions.setDeliveriesForWaybill(_deliveries);
  };

  const handleChangeOrder = (e) => {
    sortDeliveries(e.target.value);
  };

  const addressNotSupportedDeliveries = deliveries.filter(
    (d) => d.addressNotSupported || d.status === DELIVERY_STATUS_9,
  );

  return (
    <Modal>
      <Modal.Header closeButton={false}>
        <Title>운송장 출력</Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <FormLabel>사이즈 선택</FormLabel>
            <Form.Check
              label="100 x 150 사이즈 (신규)"
              type="radio"
              id="sizeType-150-v3"
              value={SIZE_TYPE_150_V3}
              name="sizeType"
              ref={register}
            />
            <Form.Check
              label="100 x 150 사이즈"
              type="radio"
              id="sizeType-150-v2"
              value={SIZE_TYPE_150_V2}
              name="sizeType"
              ref={register}
            />
            <Form.Check
              label="100 x 200 사이즈"
              type="radio"
              id="sizeType-200"
              value={SIZE_TYPE_200}
              name="sizeType"
              ref={register}
            />
          </Form.Group>
          <Form.Group>
            <FormLabel>인쇄 순서</FormLabel>
            <Form.Check
              label="접수순(기본값)"
              type="radio"
              id="sort-receiptDate"
              value={SORT.RECEIPT_DATE}
              name="sort"
              onClick={handleChangeOrder}
              ref={register}
            />
            <Form.Check
              label="상품명순"
              type="radio"
              id="sort-productName"
              value={SORT.PRODUCT_NAME}
              name="sort"
              onClick={handleChangeOrder}
              ref={register}
            />
            <Form.Check
              label="센터명 + 상품명순"
              type="radio"
              id="sort-centerAndproductName"
              value={SORT.CENTER_PRODUCT_NAME}
              name="sort"
              onClick={handleChangeOrder}
              ref={register}
            />
            <Form.Check
              label="지역그룹명순"
              type="radio"
              id="sort-allocationGroupName"
              value={SORT.ALLOCATION_GROUP_NAME}
              name="sort"
              onClick={handleChangeOrder}
              ref={register}
            />
          </Form.Group>
          <Form.Group>
            <FormLabel>
              출력 배송 목록{" "}
              <FormLabelBold>
                총 {deliveries.length}건{" "}
                {addressNotSupportedDeliveries?.length > 0 && (
                  <TextHighlight>
                    (배송불가지역 접수건 {addressNotSupportedDeliveries?.length}
                    건)
                  </TextHighlight>
                )}
              </FormLabelBold>
            </FormLabel>
            <DeliveriesWrap>
              <DeliveriesComponent />
            </DeliveriesWrap>
          </Form.Group>
          <ButtonWrap>
            <ModalButton type="submit" width="120px">
              출력
            </ModalButton>
            <ModalButton
              onClick={closeModal}
              type="button"
              variant="outline-secondary"
              width="120px"
            >
              취소
            </ModalButton>
          </ButtonWrap>
        </Form>

        {showPrintWindow && (
          <PrintPortal sizeType={getValues().sizeType}>
            <PrintContent
              deliveries={deliveriesForWaybill.map(
                (d) =>
                  new DeliveryForWaybill(
                    d,
                    getValues().sizeType,
                    agPostalCodes,
                    user.useMasking,
                  ),
              )}
              sizeType={getValues().sizeType}
            />
          </PrintPortal>
        )}
      </Modal.Body>
    </Modal>
  );
}
