import React, { useCallback, useEffect, useRef, useState } from "react";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";

import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";

import { useProfileStore } from "@/stores/hooks";

const BodyContent = styled.div``;
const Container = styled.div`
  width: 940px;
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
`;

const TitleWrap = styled(Row)`
  padding: 20px 0;
  margin: 0 0 20px;
  justify-content: space-between;
  align-items: center;
`;
const Title = styled.h2`
  margin: 0;
  font-size: 24px;
  font-weight: bold;
`;

const Section = styled.div`
  margin-bottom: 40px;
`;
const SectionTitle = styled.h4`
  font-size: 18px;
  padding-bottom: 5px;
  border-bottom: 1px solid #555;
  margin-bottom: 10px;
  font-weight: bold;
`;

const CorpInfo = styled.div`
  color: #555;
`;

const FormRow = styled(Row)`
  margin-left: -5px;
  margin-right: -5px;
`;

const FormCol = styled(Col)`
  padding-left: 5px;
  padding-right: 5px;
`;

const FormColFlex = styled(FormCol)`
  align-items: center;
  display: flex;
`;
const FormLabel = styled(Form.Label)`
  font-weight: bold;
`;
const FormControl = styled(Form.Control)`
  width: ${({ width }) => width || "100%"};
`;
const ErrorText = styled.p`
  margin-top: 2px;
  margin-bottom: 0;
  color: red;
  font-size: 12px;
`;

const SearchQueryRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
`;

const SearchQueryText = styled.div`
  margin-right: 10px;
`;

const SearchQueryRowComponent = ({ keyword, onDelete }) => {
  const handleDelete = () => {
    onDelete(keyword);
  };

  return (
    <SearchQueryRow>
      <SearchQueryText>{keyword}</SearchQueryText>
      <Button
        type="button"
        width="60px"
        size="sm"
        variant="secondary"
        onClick={handleDelete}
      >
        삭제
      </Button>
    </SearchQueryRow>
  );
};

const SectionUpdateSenderName = ({ onAfterSubmit }) => {
  const { user } = useProfileStore().state;
  const { ...actions } = useProfileStore();

  const { errors, handleSubmit, register } = useForm();

  const onSubmit = async (data) => {
    try {
      const _senderNames = [data.keyword, ...user.senderNames];

      await actions.updateSenderNames({ senderNames: _senderNames });

      onAfterSubmit();
    } catch (e) {
      window.alert(`커스텀 조회값 설정에 실패했습니다.\n${e.message}`);
    }
  };

  const handleDelete = async (keyword) => {
    try {
      let _senderNames = [...user.senderNames];

      const index = _senderNames.findIndex((el) => el === keyword);

      _senderNames.splice(index, 1);

      await actions.updateSenderNames({ senderNames: _senderNames });

      onAfterSubmit();
    } catch (e) {
      window.alert(`커스텀 조회값 삭제에 실패했습니다.\n${e.message}`);
    }
  };

  return (
    <Section>
      <SectionTitle>커스텀 조회값 설정(접수자, 고개명 일치 조회)</SectionTitle>
      <div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group as={FormRow}>
            <FormCol xs={5}>
              <FormControl name="keyword" ref={register({ required: true })} />
              <ErrorText>
                {errors.keyword && "키워드를 입력해주세요."}
              </ErrorText>
            </FormCol>
            <FormCol xs={2}>
              <Button type="submit" width="100px">
                등록
              </Button>
            </FormCol>
          </Form.Group>
        </Form>
        <div>
          {user.senderNames?.map((el, index) => {
            return (
              <SearchQueryRowComponent
                key={index}
                keyword={el}
                onDelete={handleDelete}
              />
            );
          })}
        </div>
      </div>

      {/* <Form onSubmit={handleSubmitPassword(onSubmitPassword)}>
        <Form.Group as={FormRow}>
          <FormLabel column xs="2">
            현재 비밀번호
          </FormLabel>
          <FormCol>
            <FormControl
              name="currentPassword"
              ref={registerPassword({ required: true })}
              type="password"
              width="400px"
            />
            <ErrorText>
              {errorsPassword.currentPassword &&
                "현재 비밀번호를 입력해주세요."}
            </ErrorText>
          </FormCol>
        </Form.Group>
        <Form.Group as={FormRow}>
          <FormLabel column xs="2">
            새 비밀번호
          </FormLabel>
          <FormCol>
            <FormControl
              name="password"
              ref={registerPassword({ required: true })}
              type="password"
              width="400px"
            />
            <ErrorText>
              {errorsPassword.password && "새 비밀번호를 입력해주세요."}
            </ErrorText>
          </FormCol>
        </Form.Group>
        <Form.Group as={FormRow}>
          <FormLabel column xs="2">
            새 비밀번호 확인
          </FormLabel>
          <FormCol>
            <FormControl
              name="passwordConfirm"
              ref={registerPassword({
                validate: (value) =>
                  value === password.current || "비밀번호가 일치하지 않습니다.",
              })}
              type="password"
              width="400px"
            />
            <ErrorText>{errorsPassword.passwordConfirm?.message}</ErrorText>
          </FormCol>
        </Form.Group>
        <Form.Group as={FormRow}>
          <FormCol xs={{ span: 10, offset: 2 }}>
            <Button type="submit" width="180px">
              등록
            </Button>
          </FormCol>
        </Form.Group>
      </Form> */}
    </Section>
  );
};

export default function Profile() {
  const { user } = useProfileStore().state;
  const { ...actions } = useProfileStore();

  const { errors, handleSubmit, register, reset } = useForm();
  const {
    errors: errorsPassword,
    handleSubmit: handleSubmitPassword,
    register: registerPassword,
    watch: watchPassword,
    reset: resetPassword,
  } = useForm();

  const password = useRef({});
  password.current = watchPassword("password", "");

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    reset({
      autoPrint: user.autoPrint,
      contact: user.contact,
      realname: user.realname,
      username: user.username,
    });
  }, [user]);

  const fetchUser = useCallback(async () => {
    try {
      await actions.fetchUser();
    } catch (e) {
      console.log(e);
    }
  }, []);

  const onSubmit = useCallback(
    async (data) => {
      try {
        await actions.update(data);
        alert("수정되었습니다.");
      } catch (e) {
        alert(e.message);
      }
    },
    [actions.update],
  );

  const onSubmitPassword = useCallback(
    async (data) => {
      try {
        await actions.updatePassword({
          old: data.currentPassword,
          new: data.password,
        });
        alert("수정되었습니다.");

        resetPassword();
      } catch (e) {
        alert(e.message);
      }
    },
    [actions.updatePassword],
  );

  return (
    <BodyContent>
      <Container>
        <TitleWrap>
          <Title>내 정보 수정하기</Title>
        </TitleWrap>
        <Section>
          <SectionTitle>연결된 접수점</SectionTitle>
          <CorpInfo>
            접수점: {user.spotName} / {user.spotAddress}{" "}
            {user.spotAddressDetail}
          </CorpInfo>
        </Section>
        <Section>
          <SectionTitle>내 정보 수정</SectionTitle>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group as={FormRow}>
              <FormLabel column xs="2">
                아이디
              </FormLabel>
              <FormCol>
                <FormControl
                  name="username"
                  readOnly
                  ref={register()}
                  width="400px"
                />
              </FormCol>
            </Form.Group>
            <Form.Group as={FormRow}>
              <FormLabel column xs="2">
                성명
              </FormLabel>
              <FormCol>
                <FormControl
                  name="realname"
                  ref={register({ required: true })}
                  width="400px"
                />
                <ErrorText>
                  {errors.realname && "성명을 입력해주세요."}
                </ErrorText>
              </FormCol>
            </Form.Group>
            <Form.Group as={FormRow}>
              <FormLabel column xs="2">
                연락처
              </FormLabel>
              <FormCol>
                <FormControl
                  name="contact"
                  placeholder="-를 빼고 입력해주세요."
                  ref={register({ required: true })}
                  width="400px"
                />
                <ErrorText>
                  {errors.contact && "연락처 입력해주세요."}
                </ErrorText>
              </FormCol>
            </Form.Group>
            <Form.Group as={FormRow}>
              <FormLabel column xs="2">
                운송장 자동 출력
              </FormLabel>
              <FormColFlex>
                <Form.Check
                  id="autoPrint"
                  name="autoPrint"
                  type="checkbox"
                  ref={register}
                />
              </FormColFlex>
            </Form.Group>
            <Form.Group as={FormRow}>
              <FormCol xs={{ span: 10, offset: 2 }}>
                <Button type="submit" width="180px">
                  등록
                </Button>
              </FormCol>
            </Form.Group>
          </Form>
        </Section>
        <Section>
          <SectionTitle>비밀번호 변경</SectionTitle>
          <Form onSubmit={handleSubmitPassword(onSubmitPassword)}>
            <Form.Group as={FormRow}>
              <FormLabel column xs="2">
                현재 비밀번호
              </FormLabel>
              <FormCol>
                <FormControl
                  name="currentPassword"
                  ref={registerPassword({ required: true })}
                  type="password"
                  width="400px"
                />
                <ErrorText>
                  {errorsPassword.currentPassword &&
                    "현재 비밀번호를 입력해주세요."}
                </ErrorText>
              </FormCol>
            </Form.Group>
            <Form.Group as={FormRow}>
              <FormLabel column xs="2">
                새 비밀번호
              </FormLabel>
              <FormCol>
                <FormControl
                  name="password"
                  ref={registerPassword({ required: true })}
                  type="password"
                  width="400px"
                />
                <ErrorText>
                  {errorsPassword.password && "새 비밀번호를 입력해주세요."}
                </ErrorText>
              </FormCol>
            </Form.Group>
            <Form.Group as={FormRow}>
              <FormLabel column xs="2">
                새 비밀번호 확인
              </FormLabel>
              <FormCol>
                <FormControl
                  name="passwordConfirm"
                  ref={registerPassword({
                    validate: (value) =>
                      value === password.current ||
                      "비밀번호가 일치하지 않습니다.",
                  })}
                  type="password"
                  width="400px"
                />
                <ErrorText>{errorsPassword.passwordConfirm?.message}</ErrorText>
              </FormCol>
            </Form.Group>
            <Form.Group as={FormRow}>
              <FormCol xs={{ span: 10, offset: 2 }}>
                <Button type="submit" width="180px">
                  등록
                </Button>
              </FormCol>
            </Form.Group>
          </Form>
        </Section>
        <SectionUpdateSenderName onAfterSubmit={fetchUser} />
      </Container>
    </BodyContent>
  );
}
